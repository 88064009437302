@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap');

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2.5rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    font-family: 'Comfortaa', sans-serif;
}

#basic-nav-dropdown{
    color: #3E9724;
}

/* .navbar-sticky {
    background: #333;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
} */

/* .navbar--logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
} */

/* @keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }
    to {
        transform: translateY(0rem);
    }
}

@keyframes rotate {
    0% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(0rem);
    }
} */
