@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap%27);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Comfortaa', serif;
}

h3 {
  font-weight: 700;
  margin-bottom: 20px;
}

.fineText {
  margin-top: 5%;
  font-weight: 500;
  font-size: 12px;
}

.login-form {
    background-color: #f2f2f2;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 50px;
    text-align: center;
    font-size: x-large;
}

.login {
    min-height: 85vh;
    padding-top: 150px;
}

.login-form-input {
  height: 50px;
  width: 100%;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 20px;
}

.login-form-title {
    text-align: center;
}

.login-button {
    height: 50px;
    width: 90%;
    border-radius: 20px;
    color: white;
    background-color: #60b2fe;
    border-color: blue;
    border-width: medium;
    font-size: large;
}
.login-button:hover{
    border-color: #0101ab;
}

.newuser-button {
  width: 60%;
  margin-bottom: 2%;
  border-width: 2.5px;
  border-radius: 20px;
  background-color: #FFD978;
  border-color: #FFD978;
  color: black;
  font-size: 14px;
}

.newuser-button:hover {
  border-color: #ffb700;
  background-color: #ffcf54;
  color: black;
}

.password-button {
    margin-top: 10%;
  width: 60%;
  border-radius: 20px;
  border-width: 2.5px;
  background-color: white;
  border-color: #FFD978;
  color: black;
  font-size: 14px;
}

.password-button:hover {
  border-color: #ffb700;
  background-color: #f5f5f5;
  color: black;
}

img {
    margin: 0 auto;
}

body {
}

.Admin-Buttons {
  align-content: center;
  width: 80%;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
  padding-top: 130px;
}

.Counselor-Buttons {
  align-content: center;
  min-width: 250px;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
}

.Admin-Buttons img {
  width: 20px;
  margin-right: 10px;
}

.Admin-Button {
  padding: 20px;
  border-radius: 20px;
  color: black;
  background-color: #afecaf;
  border-color: darkgreen;
  border-width: medium;
  min-width: 100%;
  margin-bottom: 5%;
}
.Admin-Button:hover {
  color: black;
  background-color: white;
}

.addCamperButton {
  margin-bottom: 20px;
}

.householdProfileButton {
  float: right;
}

h3,
h6 {
  font-family: "Comfortaa";
  font-weight: 700;
  text-align: center;
}

.householdProfileButton {
  float: right;
}

select.resize {
  width: 30%;
  min-width: 250px;
  margin-right: 10px;
}

.resize {
  min-width: 100px;
  width: 15%;
}

a.blue {
  font-size: 100%;
  color: blue;
  cursor: pointer;
}

a.red {
  font-size: 100%;
  color: red;
  cursor: pointer;
}

p.addPadding {
  margin-top: 20px;
  margin-bottom: 20px;
}

p.group {
  font-size: 100%;
  font-weight: 700;
}

p.fineText {
  font-size: 12px;
  color: #737373;
}

.resizeCredit {
  width: 200px;
  margin-right: 10px;
}

.manageTable {
  border-spacing: 2px;
  border: 1px solid #b3b3b3;
}

td.absentX {
  color: red;
}

.attendanceTable,
tr,
td {
  border: 1px solid #b3b3b3;
}

td.shadedWeek {
  background-color: #f2f2f2;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 20px;
}

/* Create two equal columns that floats next to each other */
.column4 {
  float: left;
  width: 25%;
  padding: 20px;
}

.column16 {
  float: left;
  width: 16%;
  padding: 20px;
}

.column33 {
  float: left;
  width: 33%;
  padding: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
  .column4 {
    width: 100%;
  }
  .column16 {
    width: 100%;
  }
  .column33 {
    width: 100%;
  }
}

Button.groupBtn {
  margin: 10px;
  margin-bottom: 40px;
}

Button.holidayBtn {
  margin: 10px;
}

.buttonToggle {
  text-align: center;
}

.sessionsForms {
  text-align: left;
}

.shadeGray {
  background-color: #f2f2f2;
}

.groupBox {
  border: 1px solid #3c3c3c;
}

ol {
  margin-top: 20px;
}

li {
  text-align: left;
}

.oneRow {
  width: 25%;
}

hr {
  border: 2px solid #61b3ff;
}

.sameLineForm {
  width: 25%;
}

/*label, input [type='checkbox'] {*/
/*}*/

.sameLine {
  display: inline-block;
  width: 30%;
}

.overflowTable {
  overflow-x: auto;
}

ol.holidays {
  margin-top: 10px !important;
  padding-top: 10px !important;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.disclaimerBox {
  background-color: #e0e0e0;
  margin-top: 20px;
  padding: 20px;
  font-size: 12px;
}

.notRequired {
  border: none;
}

.notRequiredGray {
  border: none;
  background-color: #f2f2f2;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
}

.grid-item {
  border: 2px solid #aaaaaa;
  margin-top: 20px;
  margin-bottom: auto;
}

.counselor-row {
  margin: 10px;
}

.counselor-title {
  margin-right: 5px;
}

.Text-Form,
.Schedule-Table,
.Checkout-Table {
  align-content: center;
  width: 80%;
  font-family: "Comfortaa", sans-serif;
  font-weight: 500;
  padding-top: 130px;
}

p {
  font-weight: 700;
  margin-bottom: 2px;
}

b {
  color: #ff0000;
}

p.terms {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
}

.backButton {
  margin-bottom: 30px;
}

.checkbox-row {
  margin-left: 8px;
}

input,
select {
  margin-bottom: 16px;
  width: 100%;
  padding: 8px 8px;
}

input:focus {
  background-color: #f2f2f2;
}

h5 {
  color: white;
  border: 2px solid #0085ff;
  background-color: #61b3ff;
  margin-bottom: 16px;
  width: 100%;
  padding: 8px 8px;
}

/* input[type="text2"], textarea {
  background-color: #D3D3D3;
} */

.check {
  width: 30px;
  height: 30px;
}

.center {
  text-align: center;
}

.buttonTxt {
  width: 150px;
  font-weight: 700;
  font-size: 150%;
}

a.cancel {
  color: red;
}

a.cancel:hover {
  color: red;
  font-weight: 700;
  text-decoration: none;
}

select.sameRow {
  width: 15%;
  margin-right: 10px;
}

.sameRow {
  width: 75%;
}

.numberInput {
  width: 15%;
}

textarea {
  width: 100%;
  height: 100px;
}

table.schedule {
  border: 1px solid black;
}

table {
  width: 100%;
}

td {
  font-weight: 500;
  padding: 20px;
}

.center-td {
  text-align: center;
}

td.checkout {
  font-size: 75%;
}

th {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.summaryBlock {
  margin-left: auto;
  margin-right: 0;
  max-width: 500px;
}

/* Chrome, Safari, Edge, Opera Number Input no Decoration */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox Number Input no Decoration */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Add styles to the form container */
.form-container {
  padding: 20px;
}

.form-popup {
  margin-top: 20px;
  border: 3px solid #f1f1f1;
  text-align: center;
}

.formBtn {
  margin-top: 5px;
}

.form-field-md {
  min-width: 300px;
}

.form-field-sm {
  min-width: 150px;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2.5rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    font-family: 'Comfortaa', sans-serif;
}

#basic-nav-dropdown{
    color: #3E9724;
}

/* .navbar-sticky {
    background: #333;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
} */

/* .navbar--logo {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    animation: rotate 0.7s ease-in-out 0.5s;
} */

/* @keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }
    to {
        transform: translateY(0rem);
    }
}

@keyframes rotate {
    0% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(0rem);
    }
} */

.footer {
  padding-top: 10px;
  background-color: rgb(255, 224, 143);
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 50px;
  min-height: 10vh;
}

.mainText,
.textbox {
  margin-left: auto;
  margin-right: auto;
  font-family: Comfortaa;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

.textbox {
  font-weight: 300;
}

