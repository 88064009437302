@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap%27");

.footer {
  padding-top: 10px;
  background-color: rgb(255, 224, 143);
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 50px;
  min-height: 10vh;
}

.mainText,
.textbox {
  margin-left: auto;
  margin-right: auto;
  font-family: Comfortaa;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

.textbox {
  font-weight: 300;
}
