@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap');

body {
  font-family: 'Comfortaa', serif;
}

h3 {
  font-weight: 700;
  margin-bottom: 20px;
}

.fineText {
  margin-top: 5%;
  font-weight: 500;
  font-size: 12px;
}

.login-form {
    background-color: #f2f2f2;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 20px;
    padding: 50px;
    text-align: center;
    font-size: x-large;
}

.login {
    min-height: 85vh;
    padding-top: 150px;
}

.login-form-input {
  height: 50px;
  width: 100%;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 20px;
}

.login-form-title {
    text-align: center;
}

.login-button {
    height: 50px;
    width: 90%;
    border-radius: 20px;
    color: white;
    background-color: #60b2fe;
    border-color: blue;
    border-width: medium;
    font-size: large;
}
.login-button:hover{
    border-color: #0101ab;
}

.newuser-button {
  width: 60%;
  margin-bottom: 2%;
  border-width: 2.5px;
  border-radius: 20px;
  background-color: #FFD978;
  border-color: #FFD978;
  color: black;
  font-size: 14px;
}

.newuser-button:hover {
  border-color: #ffb700;
  background-color: #ffcf54;
  color: black;
}

.password-button {
    margin-top: 10%;
  width: 60%;
  border-radius: 20px;
  border-width: 2.5px;
  background-color: white;
  border-color: #FFD978;
  color: black;
  font-size: 14px;
}

.password-button:hover {
  border-color: #ffb700;
  background-color: #f5f5f5;
  color: black;
}

img {
    margin: 0 auto;
}
